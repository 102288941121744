import {InformationIcon} from '@hy-vee/icons';
import {sizing, spacing} from '@hy-vee/themes/lib';
import Tooltip from '@hy-vee/web-core/lib/components/tooltip';
import styled from 'styled-components';

const StyledText = styled.span`
    align-self: center;
    display: flex;
    font-size: ${sizing[14]};
    font-weight: 400;
    line-height: ${sizing[20]};
    margin-right: ${spacing.small};
    position: relative;
    white-space: nowrap;
`;

const StyledInformationIcon = styled(InformationIcon)`
    align-self: center;
`;

const ClpeRibbonWithTooltip = ({text}) => (
    <>
        <StyledText>{text}</StyledText>
        <Tooltip content={'Price displayed does not include promotional pricing'} opacity={1}>
            <StyledInformationIcon
                ariaLabel="Price displayed does not include promotional pricing"
                informative
                size="small"
            />
        </Tooltip>
    </>
);

export default ClpeRibbonWithTooltip;
