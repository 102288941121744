import {H2, P} from '@hy-vee/web-core';
import {useSelector} from 'react-redux';
import {useQuery} from '@apollo/client';

import {graphqlClient} from '../../../graphql/graphql-client';
import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from '../../../enums/auth-types';
import {formatDate} from '../../../utils/view-helpers/date-time-view-helpers';
import {getCouponV4ByCouponIdQuery} from '../../../graphql/queries/coupon-queries';

import {
    StyledFlexContainer,
    StyledModalHeader,
    StyledModalContent,
    StyledContent,
    StyledImage,
    StyledDescription,
    StyledTerms,
    StyledEndDate
} from './styles';
import CouponActionCenter from './coupon-action-center';
import CouponUpcSection from './coupon-upc-section';

const CouponModalBody = ({couponId}) => {
    const customer = useSelector((state) => state.customer);
    const isAuthenticated = useSelector((state) => state.isAuthenticated);
    const {fuelSaverCardUuid} = customer;

    const authType = isAuthenticated ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE;
    const {data, error, loading} = useQuery(getCouponV4ByCouponIdQuery, {
        client: graphqlClient(),
        variables: {
            authType,
            couponId
        }
    });

    if (loading || error) {
        return null;
    }

    const coupon = data.couponV4;
    const upcs = coupon.upcs ? coupon.upcs : [];

    return (
        <StyledFlexContainer>
            <StyledModalHeader>{'Coupon Details'}</StyledModalHeader>
            <StyledModalContent>
                <StyledContent>
                    <StyledImage alt={coupon.brand} src={coupon.imageUrl} />
                    <StyledDescription>
                        <H2 as="h1">{coupon.valueText}</H2>
                        <P>{coupon.description}</P>
                        <StyledEndDate>{`End Date: ${formatDate(coupon.clipEndDate)}`}</StyledEndDate>
                    </StyledDescription>
                </StyledContent>
                <CouponActionCenter
                    coupon={coupon}
                    fuelSaverCardUuid={fuelSaverCardUuid}
                    isAuthenticated={isAuthenticated}
                />
                <StyledTerms>{coupon.terms}</StyledTerms>
                <CouponUpcSection upcs={upcs} />
            </StyledModalContent>
        </StyledFlexContainer>
    );
};

export default CouponModalBody;
