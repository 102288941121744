import {FC, useState, useEffect, CSSProperties, PropsWithChildren} from 'react';
import Image, {ImageLoaderProps, ImageProps} from 'next/image';

type INextImageLoaderProps = Omit<ImageProps, 'loader' | 'src'> & {
    fallbackSrc?: ImageProps['src'];
    FallbackSvg?: JSX.Element;
    useCloudFlare?: boolean;
    src?: ImageProps['src'] | null;
    style?: (CSSProperties & Record<string, string>) | null;
};

const imageLoader = ({src, width, quality}: ImageLoaderProps, useCloudFlare: boolean) => {
    if (!useCloudFlare) {
        return src;
    }

    return `https://api.hy-vee.cloud/cdn-cgi/image/f=auto,w=${width},q=${quality},dpr=1/${src}`;
};

const defaultStyle: CSSProperties = {
    objectFit: 'contain'
};

const NextImageLoader: FC<PropsWithChildren<PropsWithChildren<INextImageLoaderProps>>> = ({
    alt = 'image',
    height,
    style = defaultStyle,
    quality = 60,
    width,
    fallbackSrc,
    FallbackSvg,
    src,
    useCloudFlare = true,
    ...props
}) => {
    const [source, setSource] = useState<INextImageLoaderProps['src']>(src);
    const [useFallbackSvg, setUseFallbackSvg] = useState<boolean>(false);

    useEffect(() => {
        setSource(src || fallbackSrc);
    }, [src, fallbackSrc]);

    const handleOnError = () => {
        if (fallbackSrc) {
            setSource(fallbackSrc);
        } else if (FallbackSvg) {
            setUseFallbackSvg(true);
        }
    };

    if (useFallbackSvg || (FallbackSvg && !source)) {
        return FallbackSvg;
    }

    if (!source) {
        return null;
    }

    return (
        <Image
            alt={alt}
            height={height}
            loader={(c) => imageLoader(c, useCloudFlare)}
            onError={handleOnError}
            quality={quality}
            src={source}
            style={{
                ...defaultStyle,
                ...style
            }}
            width={width}
            {...props}
        />
    );
};

export default NextImageLoader;
