import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {hyvee} from '@hy-vee/themes';

import {setStoreSelectionTypeTab} from '../../../action-creators';
import {
    DEFAULT_VIEW,
    DELIVERY_FULFILLMENT_SELECTION,
    DELIVERY_TAB,
    PICKUP_TAB
} from '../../../enums/store-selection-tab-types';
import DeliveryTruckStoreSelection from '../../icons/delivery-truck-store-selection';
import GroceryBag from '../../icons/grocery-bag';

import {
    StyledButton,
    StyledChevron,
    StyledDescription,
    StyledH2,
    StyledStoreSelectionContainer,
    StyledTabsContainer,
    StyledDeliveryDisclaimer
} from './styles';
import StoreSelectionPickupDisplay from './pickup/store-selection-pickup-display';
import StoreSelectionDeliveryDisplay from './delivery/store-selection-delivery-display';
import StoreSelectionDeliveryFulfillmentSelectionDisplay from './delivery/store-selection-delivery-fulfillment-selection-display';

const selectionTypeTabDisplay = {
    [DEFAULT_VIEW]: null,
    [DELIVERY_FULFILLMENT_SELECTION]: <StoreSelectionDeliveryFulfillmentSelectionDisplay />,
    [DELIVERY_TAB]: <StoreSelectionDeliveryDisplay />,
    [PICKUP_TAB]: <StoreSelectionPickupDisplay />
};

class StoreSelectionBody extends Component {
    componentDidMount() {
        const tab = this.props.isMto ? DELIVERY_TAB : DEFAULT_VIEW;

        this.props.actions.setStoreSelectionTypeTab(tab);
    }

    render() {
        return (
            <StyledStoreSelectionContainer className={'store-selection'}>
                {this.props.storeSelectionTypeTab === DEFAULT_VIEW && (
                    <StyledTabsContainer>
                        <StyledButton
                            onClick={() => this.props.actions.setStoreSelectionTypeTab(PICKUP_TAB)}
                            size={'large'}
                        >
                            <GroceryBag height="40px" width="40px" />
                            <div>
                                <StyledH2>{'Pickup'}</StyledH2>
                                <StyledDescription>{'We shop the order, you pick it up.'}</StyledDescription>
                            </div>
                            <StyledChevron color={hyvee.primary} />
                        </StyledButton>
                        <StyledButton
                            onClick={() => this.props.actions.setStoreSelectionTypeTab(DELIVERY_TAB)}
                            size={'large'}
                        >
                            <DeliveryTruckStoreSelection height="40px" width="40px" />
                            <div>
                                <StyledH2>{'Delivery'}</StyledH2>
                                <StyledDescription>{'We bring the order right to you.'}</StyledDescription>
                            </div>
                            <StyledChevron color={hyvee.primary} />
                        </StyledButton>
                        <StyledDeliveryDisclaimer>
                            <StyledDescription>
                                {'A third-party service may deliver your order on behalf of Hy-Vee.'}
                            </StyledDescription>
                        </StyledDeliveryDisclaimer>
                    </StyledTabsContainer>
                )}
                {selectionTypeTabDisplay[this.props.storeSelectionTypeTab]}
            </StyledStoreSelectionContainer>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            setStoreSelectionTypeTab
        },
        dispatch
    )
});

const mapStateToProps = (state) => ({
    isMto: state.storeSelection.isMto,
    storeSelectionTypeTab: state.storeSelection.storeSelectionTypeTab
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreSelectionBody);
