import {useEffect} from 'react';
import {useInView, type IntersectionOptions} from 'react-intersection-observer';

interface InViewRefHandler {
    ref: React.RefObject<any> | ((node?: Element | null) => void);
}

type AnalyticsEvent = (elementPosition?: {
    elementPositionOnPage: number;
    pageLength: number;
    adResolution: string;
}) => void;

export const getElementPosition = (element: Element) => {
    const elementPositionOnPage = element.getBoundingClientRect().top + window.scrollY;
    const pageLength = document.documentElement.scrollHeight;
    const adResolution = `${element.clientHeight}x${element.clientWidth}`;

    return {
        adResolution,
        elementPositionOnPage,
        pageLength
    };
};

export const useInViewEvent = (options: IntersectionOptions, event: AnalyticsEvent): InViewRefHandler => {
    const {ref, inView, entry} = useInView(options);

    useEffect(() => {
        if (inView && entry) {
            const elementPosition = getElementPosition(entry.target);

            event(elementPosition);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView, entry]);

    return {
        ref
    };
};
