import {Component} from 'react';
import {CaretRightIcon} from '@hy-vee/icons';

import {
    StyledBold,
    StyledUpcContainer,
    StyledButtonContainer,
    StyledButton,
    StyledUpcSection,
    StyledUpc
} from './styles';

class CouponUpcSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shouldShowUpcs: false
        };

        this.toggleUpcSection = this.toggleUpcSection.bind(this);
    }

    toggleUpcSection() {
        this.setState((prevState) => ({
            shouldShowUpcs: !prevState.shouldShowUpcs
        }));
    }

    render() {
        return (
            <StyledUpcContainer>
                <StyledButtonContainer shouldShowUpcs={this.state.shouldShowUpcs}>
                    <StyledButton
                        aria-expanded={this.state.shouldShowUpcs}
                        onClick={() => this.toggleUpcSection()}
                        type={'button'}
                    >
                        <StyledBold>{'Available UPCs'}</StyledBold>
                        <CaretRightIcon size="small" />
                    </StyledButton>
                </StyledButtonContainer>
                {this.state.shouldShowUpcs && (
                    <StyledUpcSection>
                        {this.props.upcs.map((upc) => (
                            <StyledUpc key={upc}>{upc}</StyledUpc>
                        ))}
                    </StyledUpcSection>
                )}
            </StyledUpcContainer>
        );
    }
}

export default CouponUpcSection;
