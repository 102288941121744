import styled from 'styled-components';

const StyledGroceryBag = styled.svg`
    margin-right: 16px;
`;

const GroceryBag = ({height, width}) => (
    <StyledGroceryBag focusable={'false'} height={height} tabIndex="-1" viewBox="0 0 32 32" width={width}>
        <g fill="none" fillRule="evenodd" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.8">
            <path d="M5.958 12.107h9.775s.384 1.459 1.63 1.459c1.724 0 1.82-1.545 1.82-1.545H28l-2.204 18.368-8.434-.687-9.295.601-2.109-18.196zM8.286 12.02s-.358-.896-.476-1.746c-.082-.588.164-1.514 1.151-1.85.987-.337 1.81.505 2.139 1.177.329.673.369 2.388.369 2.388M6.263 1l2.41 7.347L5 4.136M9.665 2.917l-.832 5.51M12.223 11.347L12 4.756c-.012-.33.314-.606.729-.616l5.9-.14c.414-.01.76.25.773.58l.108 2.323" />
            <path d="M17.03 13.458S21.117 1 24.514 1c1.953 0 2.49 3.572 1.13 10.542" />
            <path d="M21.292 7.116s.496.44 1.588.44 2.085-.806 2.085-.806M20.333 8.938s.46.535 1.47.535c1.009 0 2.204-.806 2.204-.806" />
        </g>
    </StyledGroceryBag>
);

export default GroceryBag;
