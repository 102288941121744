import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {spacing, hyveeRedOutline} from '@hy-vee/themes';
import {OutlineButton} from '@hy-vee/web-core';
import Alert from '@hy-vee/web-core/lib/components/alert';

import {assignParentUrl} from '../../../utils/iframe-helpers';
import {FUEL_SAVER_PATH} from '../../../enums/rerouting-paths';
import AlertStatusType from '../../../enums/alert-status-types';
import {createClippedCouponV4} from '../../../graphql/mutations/mutation-actions/coupon-actions';
import {createClippedCouponIfNotAlreadyClippedV4} from '../../../services/coupon-service';
import {redirectToLogin} from '../../../utils/redirect-to-login';
import {useAislesOnlineRouter} from '../../../hooks/use-aisles-online-router';

const StyledButtonContainer = styled.div`
    margin-top: ${spacing.medium};
`;

const StyledLoginButton = styled(OutlineButton)`
    align-self: flex-start;
`;

const CouponActionCenter = ({coupon, isAuthenticated, fuelSaverCardUuid}) => {
    const {couponId, clipped} = coupon;
    const [isClipped, setIsClipped] = useState(clipped);
    const router = useAislesOnlineRouter();

    useEffect(() => {
        if (isAuthenticated && fuelSaverCardUuid) {
            createClippedCouponIfNotAlreadyClippedV4(fuelSaverCardUuid, couponId, setIsClipped);
        }
    }, [couponId, isAuthenticated, fuelSaverCardUuid]);

    if (!isAuthenticated) {
        return (
            <StyledLoginButton onClick={() => redirectToLogin(router.asPathWithBasePath)} theme={hyveeRedOutline}>
                {'Log In to Apply Coupon'}
            </StyledLoginButton>
        );
    } else if (!fuelSaverCardUuid) {
        return (
            <>
                <Alert>
                    {'This coupon will be automatically applied after you register a Fuel Saver + Perks® Card.'}
                </Alert>
                <StyledButtonContainer>
                    <OutlineButton onClick={() => assignParentUrl(FUEL_SAVER_PATH)} theme={hyveeRedOutline}>
                        {'Register a Fuel Saver Card'}
                    </OutlineButton>
                </StyledButtonContainer>
            </>
        );
    }

    if (!isClipped) {
        return (
            <>
                <Alert>{'This coupon will be applied at checkout.'}</Alert>
                <StyledButtonContainer>
                    <OutlineButton onClick={() => createClippedCouponV4(fuelSaverCardUuid, couponId, setIsClipped)}>
                        {'Apply Coupon'}
                    </OutlineButton>
                </StyledButtonContainer>
            </>
        );
    }

    return (
        <>
            <Alert status={AlertStatusType.SUCCESS_ALERT}>{'This coupon will be applied at checkout.'}</Alert>
            <StyledButtonContainer>
                <OutlineButton disabled>{'Coupon Applied'}</OutlineButton>
            </StyledButtonContainer>
        </>
    );
};

export default CouponActionCenter;
