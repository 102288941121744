import {useSelector, useDispatch} from 'react-redux';
import Modal from '@hy-vee/web-core/lib/components/modal';

import {toggleActiveModal as toggleActiveModalAction} from '../../../action-creators';
import StoreSelectionBody from '../store-selection/store-selection-body';
import {ReservationProvider} from '../../../hooks/use-reservation';
import timeReservation from '../../time-reservation';
import ReservationStepperContent from '../reservation-stepper/reservation-stepper-content';

const wrappedReservationStepper = ({closeModal}) => (
    <ReservationProvider>
        <ReservationStepperContent closeModal={closeModal} />
    </ReservationProvider>
);

const topLevelModalsEnum = {
    RESERVATION_STEPPER: {
        component: wrappedReservationStepper,
        size: 'large'
    },
    STORE_SELECTION: {
        component: StoreSelectionBody,
        size: 'medium'
    },
    TIME_RESERVATION: {
        component: timeReservation,
        size: 'large'
    }
};

const TopLevelModals = () => {
    const activeModal = useSelector((state) => state.activeModal);
    const dispatch = useDispatch();
    const toggleActiveModal = () => dispatch(toggleActiveModalAction());
    const modalToDisplay = topLevelModalsEnum[activeModal];

    if (!activeModal || !modalToDisplay) {
        return null;
    }

    const ModalComponent = modalToDisplay.component;
    const modalSize = modalToDisplay.size;

    return (
        <Modal autoFocus data-testid="top-level-modal" onClose={toggleActiveModal} padding={false} size={modalSize}>
            <ModalComponent closeModal={toggleActiveModal} />
        </Modal>
    );
};

export default TopLevelModals;
