import {FC, PropsWithChildren} from 'react';
import {IDType} from '@hy-vee/ts-utils';
import Modal from '@hy-vee/web-core/lib/components/modal';

import {HYFISH_WEB_SHARED_LIST} from 'client/enums/feature-toggle-names';
import {useFeatureToggle} from 'client/context/feature-toggle';

import ClientOnlyPortal from '../../client-only-portal';
import modalStyles from '../../lists-v2/modal.module.css';
import AddToListsModalContainerV2 from '../../lists-v2/add-to-lists/add-to-lists-modal-container';

import AddToListsModalContainer from './add-to-lists-modal-container';

interface IAddToListModalProps {
    onClose: Function;
    aisleId?: string;
    productId?: IDType | null;
    responseProductId?: string | null;
    responseId?: string | null;
}

const AddToListsModal: FC<PropsWithChildren<PropsWithChildren<IAddToListModalProps>>> = ({
    onClose,
    aisleId,
    productId,
    responseId,
    responseProductId
}) => {
    const {featureEnabled} = useFeatureToggle();
    const isHyfishWebSharedListEnabled = featureEnabled(HYFISH_WEB_SHARED_LIST);

    return productId ? (
        <ClientOnlyPortal selector="body">
            <Modal
                className={isHyfishWebSharedListEnabled && modalStyles.modalContainer}
                onClose={onClose}
                title={!isHyfishWebSharedListEnabled && 'Add or Remove from List'}
            >
                {isHyfishWebSharedListEnabled ? (
                    <AddToListsModalContainerV2
                        aisleId={aisleId}
                        closeModal={onClose}
                        productId={productId}
                        responseId={responseId}
                        responseProductId={responseProductId}
                    />
                ) : (
                    <AddToListsModalContainer
                        aisleId={aisleId}
                        closeModal={onClose}
                        productId={productId}
                        responseId={responseId}
                        responseProductId={responseProductId}
                    />
                )}
            </Modal>
        </ClientOnlyPortal>
    ) : null;
};

export default AddToListsModal;
