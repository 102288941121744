import {FC, PropsWithChildren, memo} from 'react';
import {useMutation} from '@apollo/client';
import {IDType} from '@hy-vee/ts-utils';

import {ProductCardQuery} from 'autogen/ProductCardQuery';
import {PRICING_ITEM_API} from 'client/enums/feature-toggle-names';
import {createCartItemMutation} from 'client/graphql/mutations/product-card-mutations';
import {useCitrusActions} from 'client/hooks/citrus-hooks';
import {useCustomerData} from 'client/hooks/customer-hooks';
import {useFeatureToggle} from 'client/context/feature-toggle';
import {
    createCartItemProductMutation,
    createCartItemProductMutation_createCartItem,
    createCartItemProductMutation_createCartItem_retailItem,
    createCartItemProductMutation_createCartItem_storeProduct
} from 'autogen/createCartItemProductMutation';
import {useCartItems} from 'client/hooks/cart-hooks';

import {ProductCardSimple} from '..';

import {getWarningMessageForProductCardFromCart, onChangeAmountInCart} from './product-card-helpers';
import {IProductCardSimpleProps} from './product-card-simple';

const INCLUDE_CART_ITEMS = true;

export interface IOnNewItemAddedProps {
    storeProduct: createCartItemProductMutation_createCartItem_storeProduct;
    retailItem: createCartItemProductMutation_createCartItem_retailItem;
}
export interface IProductCardCartProps
    extends Omit<
        IProductCardSimpleProps,
        'getWarning' | 'hasStoreSelected' | 'isAddToCartLoading' | 'onChangeAmountInCart' | 'quantity'
    > {
    productId: IDType;
    aisleId?: string;
    adId?: string | null;
    slotTypeId?: number | null;
    sponsored?: string | null;
    swimlaneType?: string;
    responseProductId?: string | null;
    onNewItemAdded?: (cartItem: createCartItemProductMutation_createCartItem) => void;
    isList?: boolean;
}

const ProductCardCart: FC<PropsWithChildren<PropsWithChildren<IProductCardCartProps>>> = ({
    onNewItemAdded,
    productId,
    responseProductId,
    adId,
    slotTypeId,
    swimlaneType = '',
    aisleId,
    responseId,
    isList = false,
    ...props
}) => {
    const {activeCart} = useCustomerData(INCLUDE_CART_ITEMS);
    const {getCartItem} = useCartItems(true);
    const {reportClick} = useCitrusActions();
    const {featureEnabled} = useFeatureToggle();
    const pricingItemApiToggle = featureEnabled(PRICING_ITEM_API);

    const [createCartItemCallback, {loading}] = useMutation(createCartItemMutation, {
        onCompleted: (data: createCartItemProductMutation) => {
            const {createCartItem} = data;

            if (!createCartItem) return;

            if (typeof onNewItemAdded === 'function') {
                onNewItemAdded(createCartItem);
            }
        }
    });

    const cartItem = getCartItem(productId);

    const getWarning = (data: ProductCardQuery) =>
        getWarningMessageForProductCardFromCart(activeCart, data, pricingItemApiToggle);

    const onChangeAmountInCartWrapper = (product, storeProduct, customer, coupon) => {
        return onChangeAmountInCart({
            aisleId,
            cartItem,
            coupon,
            createAdClickEvent: () => adId && reportClick(adId),
            createCartItemCallback,
            customer,
            position: props.index,
            product,
            responseId,
            responseProductId,
            storeProduct,
            swimlaneType
        });
    };

    const quantity = cartItem ? cartItem.quantity : 0;

    return (
        <ProductCardSimple
            {...props}
            adId={adId}
            aisleId={aisleId}
            getWarning={getWarning}
            hasStoreSelected={Boolean(activeCart?.storeId)}
            isAddToCartLoading={loading}
            isList={isList}
            onChangeAmountInCart={onChangeAmountInCartWrapper}
            productId={productId}
            quantity={quantity}
            responseId={responseId}
            responseProductId={responseProductId}
            slotTypeId={slotTypeId}
            swimlaneType={swimlaneType}
        />
    );
};

export default memo(ProductCardCart);
