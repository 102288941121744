import styled, {css} from 'styled-components';
import {spacing, sizing, colors} from '@hy-vee/themes';
import {H5, P} from '@hy-vee/web-core';

import {transitionDuration, lightBorder} from '../../../styles/style-constants';

export const StyledFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledModalHeader = styled(H5)`
    align-items: center;
    background: ${colors.grey[100]};
    display: flex;
    font-size: ${sizing[20]};
    padding: ${spacing.medium};
`;

export const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${spacing.medium};
`;

export const StyledContent = styled.div`
    display: flex;
    margin-bottom: ${spacing.medium};
`;

export const StyledImage = styled.img`
    max-height: 100px;
    height: 100%;
`;

export const StyledDescription = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: ${spacing.large};
`;

export const StyledTerms = styled(P)`
    font-size: ${sizing[12]};
    margin: ${spacing.medium} 0;
`;

export const StyledEndDate = styled(P)`
    font-size: ${sizing[12]};
    font-weight: 500;
    margin-top: ${spacing.small};
`;

export const StyledUpcContainer = styled.div`
    border: ${lightBorder};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;

export const StyledButtonContainer = styled.div`
    display: flex;

    ${(props) =>
        props.shouldShowUpcs
            ? css`
                  svg {
                      transform: rotate(270deg);
                      transition: transform ${transitionDuration} ease;
                  }
              `
            : css`
                  svg {
                      transform: rotate(90deg);
                      transition: transform ${transitionDuration} ease;
                  }
              `};
`;

export const StyledButton = styled.button`
    display: flex;
    justify-content: space-between;
    padding: 12px;
    width: 100%;

    :hover,
    :focus,
    svg:focus {
        outline: none;
    }
`;

export const StyledBold = styled(P)`
    font-weight: 500;
    margin-bottom: 0;
`;

export const StyledUpcSection = styled.div`
    column-width: 110px;
    padding: 12px;
`;

export const StyledUpc = styled(P)`
    font-size: ${sizing[14]};
`;
